import styled, { css } from "styled-components"

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 16px;
  margin-bottom: -4px;

  ${props =>
    props.round
      ? css`
          .gatsby-image-wrapper,
          > img {
            width: 38px !important;
            height: 38px !important;
          }
          img {
            border-radius: 50%;
          }
        `
      : css`
          .gatsby-image-wrapper,
          > img {
            max-height: 38px !important;
            max-width: 80px !important;
            height: 100% !important;
            width: 100% !important;
          }
        `}

  span {
    font-weight: 500;
    font-size: 13px;
    line-height: 28px;
    :nth-child(2) {
      margin-left: 8px;
    }
  }
`
