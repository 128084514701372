import styled, { css } from "styled-components"
import device from "../../theme/devices"

export const Container = styled.div`
  position: relative;
  ${props =>
    props.padTop &&
    css`
      padding-top: 100px;
    `};
`

export const ContentContainer = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding-top: 20px;
  margin-top: ${props => (props.accountPadding ? 100 : 0)}px;
  padding-bottom: 25px;
  background: rgba(0, 0, 0, 0.3);

  @media (${device.desktop}) {
    padding-top: 80px;
    padding-bottom: 70px;
  }

  > div {
    grid-template-rows: 1fr auto auto auto;
    grid-row-gap: 21px;
    height: 100%;
  }

  h6 {
    grid-row: 1;
    grid-column: 1 / 7;
    margin-top: 0;

    @media (${device.desktop}) {
      grid-column: 2 / 10;
    }
  }

  .article-title {
    grid-row: 2;
    grid-column: 1 / 7;
    display: block;

    @media (${device.desktop}) {
      grid-column: 2 / 6;
    }
  }

  .description {
    grid-row: 3;
    grid-column: 1 / 7;
    display: block;

    @media (${device.desktop}) {
      grid-column: 2 / 6;
    }
  }

  .link-wrapper {
    grid-row: 4;
    grid-column: 1 / 7;
    @media (${device.desktop}) {
      grid-column: 2 / 6;
    }
  }

  .tags {
    display: none;
    @media (${device.desktop}) {
      grid-row: 4;
      grid-column: 10/13;
      display: initial;
    }
  }
`
