import styled, { css } from "styled-components"
import GridLayout from "../../layouts/GridLayout"
import device from "../../theme/devices"

export const FilterRow = styled(GridLayout)`
  padding-top: 70px;
  padding-bottom: 65px;
  align-items: baseline;
  ${props =>
    props.padTop &&
    css`
      padding-top: 50px;
    `};

  > .input-wrapper {
    @media (${device.phone}) {
      display: none !important;
    }
    @media (${device.tablet}) {
      grid-column: 4 / 7;
    }
    @media (${device.desktop}) {
      grid-column: 5 / 12;
    }

    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
  }
`

export const ButtonContainer = styled.div`
  grid-column: 1 / 7;
  cursor: pointer;
  button {
    cursor: inherit;
  }
  @media (${device.tablet}) {
    grid-column: 1 / 4;
  }
  @media (${device.desktop}) {
    grid-column: 2 / 5;
  }
  display: flex;
  flex-direction: row;
  align-items: center;

  span {
    font-weight: 500;
    margin-left: 12px;
  }
`
