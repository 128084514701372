import React from "react"
import styled from "styled-components"
import Img from "gatsby-image"
import * as S from "./styled"
import dark from "../../theme/dark"
import TagSet from "../TagSet"
import { graphql, Link, navigate, useStaticQuery } from "gatsby"
import { urlFor } from "../../util/content-links"
import SearchContext from "../../context/SearchContext"
import Avatar from "../Avatar"
import { DomainAwareThemeProvider } from "../DomainAwareThemeProvider"

const Article = styled.article`
  display: flex;
  flex-direction: column;
  &:hover {
    a * {
      text-decoration: underline;
    }
  }
`

const ArticleLink = styled(Link)`
  color: ${props => props.theme.color};

  &:hover {
    * {
      text-decoration: underline;
    }
  }
`

const Overlay = styled.div`
  position: absolute;
  pointer-events: none;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(transparent 80%, rgba(0, 0, 0, 0.6));
`

const ArticleOverviewCard = ({
  __typename,
  withDescription,
  big,
  locale,
  slug,
  categories,
  author,
  title,
  seo: { image, description } = {},
}) => {
  const { allDatoCmsTranslation } = useStaticQuery(graphql`
    {
      allDatoCmsTranslation {
        edges {
          node {
            tags
            locale
          }
        }
      }
    }
  `)
  const labels = allDatoCmsTranslation.edges.find(
    edge => edge.node.locale === locale
  ).node
  return (
    <Article>
      <S.ImageContainer
        className={big ? "big" : ""}
        onClick={() => navigate(urlFor(__typename, locale, slug))}
      >
        <DomainAwareThemeProvider theme={dark}>
          <Img {...image} />
          <Overlay color={image?.colors?.[0]} />
          <Avatar {...author} />
        </DomainAwareThemeProvider>
      </S.ImageContainer>
      <ArticleLink to={urlFor(__typename, locale, slug)}>
        {withDescription ? (
          <S.TitleBig className={title}>{title}</S.TitleBig>
        ) : (
          <S.Title className={title}>{title}</S.Title>
        )}
      </ArticleLink>
      {withDescription && <S.Description>{description}</S.Description>}
      {categories && (
        <SearchContext.Consumer>
          {({ search }) => (
            <TagSet
              using={categories}
              abbreviate
              title={labels.tags}
              css={{ marginTop: "auto" }}
              onTagClick={t => search(locale, [t.originalId || t.id], null)}
            />
          )}
        </SearchContext.Consumer>
      )}
    </Article>
  )
}

export default ArticleOverviewCard
