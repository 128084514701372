import styled from "styled-components"
import * as S from "./ArticleOverviewLayout.styled"

export const FirstTwo = styled(S.Impactful)`
  > :first-child:last-child {
    min-height: 100vh;
  }
`

export const ArticleGrid = S.ArticleGrid
