export const fakePageTransition = (doBetween, pageTransitionRef) => {
  window.dispatchEvent(
    new CustomEvent("gatsby-plugin-page-transition::exit", {
      detail: { pathname: "" },
    })
  )
  setTimeout(() => {
    doBetween()
    setTimeout(() => pageTransitionRef?.current?.setIn(true), 50)
  }, 250)
}
