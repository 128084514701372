import React from "react"
import * as S from "./styled"
import { Span } from "../styles/TextStyles.styled"

const Pager = ({ pages, current, onPageClick, forGridLayout, ...props }) => (
  <S.Container forGridLayout={forGridLayout} {...props}>
    {Array(pages)
      .fill(0)
      .map((_, i) => (
        <Span
          className={`mono ${current === i && "current"}`}
          key={i}
          onClick={() => onPageClick(i)}
        >
          {i + 1}
        </Span>
      ))}
  </S.Container>
)

export default Pager
