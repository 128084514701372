import styled from "styled-components"
import { P, SubTitle } from "../styles/TextStyles.styled"
import device from "../../theme/devices"

export const ImageContainer = styled.div`
  position: relative;
  cursor: pointer;
  overflow: hidden;

  .avatar {
    position: absolute;
    bottom: 20px;
    left: 20px;
    right: 20px;
    line-height: 14px;
  }
  &:hover {
    a * {
      text-decoration: underline;
    }

    .gatsby-image-wrapper {
      transform: scale(1.06);
    }
  }
  .gatsby-image-wrapper {
    height: 390px;
    transition: transform 650ms ease-out;
    @media (${device.desktop}) {
      height: 455px;
    }
  }

  &.big {
    .gatsby-image-wrapper {
      @media (${device.desktop}) {
        height: 610px;
      }
    }
  }
`

export const TitleBig = styled(SubTitle)`
  margin-top: 24px;
  margin-bottom: 26px;
  display: inline-block;
`

export const Title = styled.span`
  font-weight: 500;
  font-size: 22px;
  line-height: 28px;
  margin-top: 24px;
  display: inline-block;
`

export const Description = styled(P)`
  margin-top: 0;
`
