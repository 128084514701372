import styled, { css } from "styled-components"
import device from "../../theme/devices"

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;

  ${props =>
    props.forGridLayout &&
    css`
      grid-column: 1 / 7;
      @media (${device.desktop}) {
        grid-column: 2 / 12;
        justify-self: flex-end;
      }
    `}

  > * {
    cursor: pointer;
    margin-left: 95px;
    font-size: 14px;
    line-height: 18px;
    font-weight: normal;

    &.current {
      border-bottom: 3px solid;
    }
  }
`
