import React, { useContext, useEffect } from "react"
import LinkedHero from "../sections/generic/LinkedHero"
import PropTypes from "prop-types"
import LinkedSectionTitle from "../components/SectionTitles/LinkedSectionTitle"
import ArticleOverviewCard from "../components/ArticleOverviewCard"
import * as S from "./ArticleOverviewLayout.styled"
import PageWrapperContext from "../context/PageWrapperContext"
import dark from "../theme/dark"

const ArticleOverviewLayout = ({
  locale,
  hero,
  impactful,
  articles = [],
  articlesSectionLabel,
  filterComponent,
  featured,
  loading,
  loadingLabel,
  error,
  errorLabel,
  ...props
}) => {
  let firstArticleSet, secondArticleSet
  if (featured && articles.edges.length > 6) {
    firstArticleSet = articles.edges.slice(0, 6)
    secondArticleSet = articles.edges.slice(6)
  } else if (articles.edges) {
    firstArticleSet = articles.edges
  } else {
    // Because on the n+1th page, we're calling dato's API so the format is a lil bit different (no edges > node)
    firstArticleSet = articles
  }
  const {
    setHeaderTheme,
    setHeaderCollapsedTheme,
    setTheme,
    setFixedHeader,
  } = useContext(PageWrapperContext)
  useEffect(() => {
    setHeaderTheme(hero ? dark : PageWrapperContext.default.theme)
    setHeaderCollapsedTheme(null)
    setTheme(PageWrapperContext.default.theme)
    setFixedHeader(!!hero)
    return () => setFixedHeader(false)
  })
  return (
    <div>
      {hero && (
        <LinkedHero item={hero} visual={(hero?.bigSeo || hero?.seo)?.image} />
      )}
      {filterComponent}
      {error && <div style={{ textAlign: "center" }}>{errorLabel}</div>}
      {impactful?.articles?.edges && (
        <S.Impactful loading={loading}>
          <LinkedSectionTitle title={impactful.label} />
          {impactful.articles.edges.map(({ node, ...data }, i) => (
            <ArticleOverviewCard
              withDescription
              big
              key={i}
              {...node}
              {...data}
            />
          ))}
        </S.Impactful>
      )}
      <S.ArticleGrid
        noPadding={!impactful || impactful.articles.edges.length === 0}
        loading={loading}
      >
        {firstArticleSet?.length > 0 && (
          <LinkedSectionTitle title={articlesSectionLabel} />
        )}
        {firstArticleSet.map(({ node, ...data }, i) => (
          <ArticleOverviewCard
            key={i}
            locale={node?.locale || locale}
            {...node}
            {...data}
          />
        ))}
      </S.ArticleGrid>
      {featured && (
        <LinkedHero
          item={featured.article}
          title={featured.title}
          visual={(featured.article.bigSeo || featured.article.seo).image}
        />
      )}
      {secondArticleSet?.length > 0 && (
        <S.ArticleGrid>
          {secondArticleSet.map(({ node }, i) => (
            <ArticleOverviewCard key={i} {...node} />
          ))}
        </S.ArticleGrid>
      )}
      {props.children}
    </div>
  )
}

ArticleOverviewLayout.propTypes = {
  impactful: PropTypes.shape({
    label: PropTypes.string,
    articles: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }),
  featured: PropTypes.shape({
    title: PropTypes.string,
    article: PropTypes.any,
  }),
}

export default ArticleOverviewLayout
