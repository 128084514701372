import React, { useEffect, useState } from "react"
import { IconButtonWithShadow } from "../styles/Buttons.styled"
import { ReactComponent as FilterIcon } from "../../assets/ic-filter.svg"
import { Span } from "../styles/TextStyles.styled"
import Input from "../Input"
import { ReactComponent as SearchIcon } from "../../assets/ic-search.svg"
import * as S from "./styled"

const ArticlesFilterRow = ({
  padTop,
  openSearch,
  openSearchButtonLabel,
  searchPlaceholder,
  searchTerm,
  onSearchTermChange,
}) => {
  const [term, setTerm] = useState(searchTerm)
  useEffect(() => setTerm(searchTerm), [searchTerm])
  return (
    <S.FilterRow
      padTop={padTop}
      as="form"
      onSubmit={e => {
        e.preventDefault()
        e.stopPropagation()
        onSearchTermChange(term)
      }}
    >
      <S.ButtonContainer onClick={openSearch}>
        <IconButtonWithShadow type="button">
          <FilterIcon />
        </IconButtonWithShadow>
        <Span>{openSearchButtonLabel}</Span>
      </S.ButtonContainer>
      <Input
        prefix={<SearchIcon />}
        placeholder={searchPlaceholder}
        value={term}
        onChange={e => setTerm(e.target.value)}
      />
    </S.FilterRow>
  )
}

export default ArticlesFilterRow
