import React, { useContext, useEffect } from "react"
import LinkedSectionTitle from "../components/SectionTitles/LinkedSectionTitle"
import { ArticleGrid, FirstTwo } from "./SearchResultsLayout.styled"
import ArticleOverviewCard from "../components/ArticleOverviewCard"
import PageWrapperContext from "../context/PageWrapperContext"

const SearchResultsLayout = ({
  locale,
  filterComponent,
  label,
  articles,
  loading,
  loadingLabel,
  error,
  errorLabel,
  noResultsLabel,
  ...props
}) => {
  const {
    setHeaderTheme,
    setHeaderCollapsedTheme,
    setTheme,
    setFixedHeader,
  } = useContext(PageWrapperContext)
  useEffect(() => {
    setHeaderTheme(PageWrapperContext.default.theme)
    setHeaderCollapsedTheme(null)
    setTheme(PageWrapperContext.default.theme)
    setFixedHeader(false)
    return () => setFixedHeader(false)
  })
  return (
    <div>
      {filterComponent}
      {error && <div style={{ textAlign: "center" }}>{errorLabel}</div>}
      {!loading && !error && articles?.length === 0 && (
        <div style={{ textAlign: "center" }}>{noResultsLabel}</div>
      )}
      {!error && (
        <>
          <FirstTwo loading={loading}>
            <LinkedSectionTitle title={articles?.length > 0 ? label : ""} />
            {articles?.slice(0, 2).map(a => (
              <ArticleOverviewCard
                withDescription
                big
                key={a.id}
                {...a}
                locale={locale}
              />
            ))}
          </FirstTwo>
          <ArticleGrid loading={loading}>
            {articles?.slice(2).map(a => (
              <ArticleOverviewCard
                withDescription
                big
                key={a.id}
                {...a}
                locale={locale}
              />
            ))}
          </ArticleGrid>
        </>
      )}
      {props.children}
    </div>
  )
}

export default SearchResultsLayout
