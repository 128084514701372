import styled, { css } from "styled-components"
import GridLayout from "./GridLayout"
import device from "../theme/devices"

export const Impactful = styled(GridLayout)`
  transition: opacity 250ms ease-out;
  opacity: ${props => (props.loading ? 0 : 1)};
  article {
    grid-column: 1 / 7;
    margin-bottom: 20px;
  }
  @media (${device.tablet}) {
    ${[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map(
      i => css`
        article:nth-child(${i + 1}) {
          grid-row: ${Math.ceil(i / 2)};
          grid-column: ${i % 2 === 1 ? "1 / 4" : "4 / 7"};
        }
      `
    )}
  }
  @media (${device.desktop}) {
    ${[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map(
      i => css`
        article:nth-child(${i + 1}) {
          grid-row: ${Math.ceil(i / 2) + 1};
          grid-column: ${i % 2 === 1 ? "2 / 7" : "7 / 12"};
        }
      `
    )}
  }
`

export const ArticleGrid = styled(GridLayout)`
  margin-top: ${props => (props.noPadding ? 0 : 50)}px;
  transition: opacity 250ms ease-out;
  opacity: ${props => (props.loading ? 0 : 1)};
  article {
    margin-bottom: 50px;
  }
  @media (${device.desktop}) {
    margin-top: ${props => (props.noPadding ? 0 : 130)}px;

    article {
      margin-bottom: 120px;
    }
  }
  ${[1, 2, 3, 4, 5, 6, 7, 8, 9].map(
    i => css`
      > article:nth-of-type(${i}) {
        grid-row: ${1 + i};
        grid-column: 1 / 7;

        @media (${device.tablet}) {
          grid-row: ${1 + Math.floor((i - 1) / 2)};
          grid-column: ${i % 2 === 1 ? "1 / 4" : "4 / 7"};
        }

        @media (${device.desktop}) {
          grid-row: ${Math.floor(2 + (i - 1) / 3)};
          // TODO prettier way of doing this, while working on IE
          grid-column: ${i === 1 || i === 7
            ? "2 / 5"
            : i === 2 || i === 8
            ? "5 / 8"
            : i === 3 || i === 9
            ? "8 / 12"
            : i === 4
            ? "2 / 6"
            : i === 5
            ? "6 / 9"
            : i === 6
            ? "9 / 12"
            : ""};
        }
      }
    `
  )};
`
