import React from "react"
import { H3 } from "../styles/TextStyles.styled"
import ArrowedLink from "../Links/ArrowedLink"
import styled from "styled-components"
import device from "../../theme/devices"

const Title = styled.div`
  grid-row: ${props => props.gridRow};
  grid-column: 1 / 4;
  margin-top: 0;

  @media (${device.desktop}) {
    grid-column: 2 / 6;
  }
`

const Link = styled(ArrowedLink)`
  grid-row: ${props => props.gridRow};
  grid-column: 6;
  justify-self: end;
  padding-right: ${props => props.padRight && "10px"};
  word-break: break-word;
  hyphens: auto;

  @media (${device.desktop}) {
    grid-column: 11 / 13;
    justify-self: start;
  }
`

const LinkedSectionTitle = ({
  cta,
  link,
  gridRow = 1,
  title,
  titleAs = H3,
  transparentLinkBackground,
}) => {
  return (
    <>
      <Title as={titleAs} gridRow={gridRow}>
        {title}
      </Title>
      {(cta || link) && (
        <Link
          className="cta"
          ctaData={cta}
          link={link}
          hideBackground={transparentLinkBackground}
          hideLabelOnPhones={true}
        />
      )}
    </>
  )
}

export default LinkedSectionTitle
