import React, { useContext, useEffect, useRef, useState } from "react"
import { graphql } from "gatsby"
import Pager from "../components/Pager"
import GridLayout from "../layouts/GridLayout"
import { HelmetDatoCms } from "gatsby-source-datocms"
import SearchContext from "../context/SearchContext"
import { useQueryParam } from "gatsby-query-params"
import PageTransition from "gatsby-plugin-page-transitions"
import ArticleOverviewLayout from "../layouts/ArticleOverviewLayout"
import ArticlesFilterRow from "../components/ArticlesFilterRow"
import SearchResultsLayout from "../layouts/SearchResultsLayout"
import { fakePageTransition } from "../util/fakePageTransition"
import { articlesPageSize } from "../util/pagination"

const ArticlesOverview = ({
  data: { articles, ...pageData },
  pageContext: { pageCount, locale },
}) => {
  const page = useQueryParam("page", null)
  const term = useQueryParam("term", "")
  const tags = useQueryParam("tags", [])
  const [pageTerm, setPageTerm] = useState(term)

  const { openSearchModal, search } = useContext(SearchContext)

  const doSearch = (...params) =>
    fakePageTransition(() => search(...params), pageTransitionRef)

  const [searchData, setSearchData] = useState({
    loading: false,
    data: {},
    error: null,
  })
  useEffect(() => {
    setPageTerm(term || "")

    if (!page && !term && tags.length === 0) {
      setSearchData({ data: {}, loading: false, error: null })
      return
    }
    setSearchData(s => ({ ...s, loading: true }))
    fetch("/.netlify/functions/search", {
      method: "POST",
      body: JSON.stringify({
        locale: locale,
        tags: Array.isArray(tags) ? tags : tags ? [tags] : [],
        term: term || "",
        page: page || 1,
      }),
    })
      .then(res => res.json())
      .then(data => setSearchData({ loading: false, error: null, data }))
      .catch(error => setSearchData({ loading: false, error, data: {} }))
  }, [page, term, JSON.stringify(tags)])

  const pageTransitionRef = useRef(null)

  const filterRow = (
    <ArticlesFilterRow
      openSearch={openSearchModal}
      padTop={!!page || term || tags.length > 0}
      searchTerm={pageTerm}
      searchPlaceholder={pageData.labels.searchBySubject}
      openSearchButtonLabel={pageData.labels.searchBySubjectOrTargetGroup}
      onSearchTermChange={term => doSearch(locale, tags, term)}
    />
  )

  return (
    <PageTransition ref={pageTransitionRef}>
      <SearchContext.Provider value={{ search: doSearch }}>
        <HelmetDatoCms seo={pageData.page.seoMetaTags} />
        {!term && (!tags || tags?.length === 0) ? (
          <ArticleOverviewLayout
            locale={locale}
            articles={!page ? articles : searchData.data.articles}
            filterComponent={filterRow}
            hero={!page && pageData.hero}
            featured={
              !page &&
              pageData.featured && {
                title: pageData.labels.featured,
                article: pageData.featured,
              }
            }
            impactful={
              !page &&
              pageData.mostImpactful && {
                label: pageData.labels.mostImpactfulProjects,
                articles: pageData.mostImpactful,
              }
            }
            articlesSectionLabel={pageData.labels.otherProjects}
            loading={searchData.loading}
            loadingLabel={pageData.labels.loading}
            error={searchData.error}
            errorLabel={pageData.labels.errorLoading}
          >
            {!searchData.loading && !searchData.error && pageCount > 1 && (
              <GridLayout>
                <Pager
                  pages={pageCount}
                  current={page ? page - 1 : 0}
                  forGridLayout
                  onPageClick={page =>
                    doSearch(
                      locale,
                      tags,
                      term,
                      page === 0 ? undefined : page + 1
                    )
                  }
                />
              </GridLayout>
            )}
          </ArticleOverviewLayout>
        ) : (
          <SearchResultsLayout
            locale={locale}
            filterComponent={filterRow}
            label={
              pageData.labels.searchResultsFor +
              " " +
              [
                ...(pageTerm ? [pageTerm] : []),
                ...(searchData?.data?.categories || []).map(
                  c => c.excerpt || c.shortName || c.title
                ),
              ].join(", ")
            }
            articles={searchData.data.articles}
            loading={searchData.loading}
            loadingLabel={pageData.labels.loading}
            error={searchData.error}
            errorLabel={pageData.labels.errorLoading}
            noResultsLabel={pageData.labels.noArticlesFound}
          >
            {!searchData.loading &&
              searchData.data?.count > articlesPageSize(term, tags) && (
                <GridLayout>
                  <Pager
                    pages={
                      Math.ceil(
                        searchData?.data?.count / articlesPageSize(term, tags)
                      ) || 0
                    }
                    current={page ? page - 1 : 0}
                    forGridLayout
                    onPageClick={page =>
                      doSearch(
                        locale,
                        tags,
                        term,
                        page === 0 ? undefined : page + 1
                      )
                    }
                  />
                </GridLayout>
              )}
          </SearchResultsLayout>
        )}
      </SearchContext.Provider>
    </PageTransition>
  )
}

export default ArticlesOverview

export const query = graphql`
  query(
    $id: String!
    $locale: String!
    $articles: [String]
    $featuredHero: String
    $featuredMiddle: String
    $mostImpactful: [String]
  ) {
    labels: datoCmsTranslation(locale: { eq: $locale }) {
      searchBySubjectOrTargetGroup
      searchBySubject
      mostImpactfulProjects
      otherProjects
      featured
      loading
      errorLoading
      noArticlesFound
      searchResultsFor
    }
    page: datoCmsArticlesPage(id: { eq: $id }) {
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
    }
    articles: allDatoCmsArticle(
      filter: { id: { in: $articles }, locale: { eq: $locale } }
      sort: { fields: meta___publishedAt, order: DESC }
    ) {
      edges {
        node {
          ...ArticleCardData
        }
      }
    }
    hero: datoCmsArticle(id: { eq: $featuredHero }) {
      ...ArticleCardData
      bigSeo: seo {
        description
        image {
          fluid(maxWidth: 1440, imgixParams: { fm: "jpg", auto: "compress" }) {
            ...GatsbyDatoCmsFluid
          }
        }
      }
    }
    featured: datoCmsArticle(id: { eq: $featuredMiddle }) {
      ...ArticleCardData
      bigSeo: seo {
        description
        image {
          fluid(maxWidth: 1440, imgixParams: { fm: "jpg", auto: "compress" }) {
            ...GatsbyDatoCmsFluid
          }
        }
      }
    }
    mostImpactful: allDatoCmsArticle(filter: { id: { in: $mostImpactful } }) {
      edges {
        node {
          ...ArticleCardData
        }
      }
    }
  }
`
