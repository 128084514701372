import React from "react"
import * as S from "./styled"
import { Span } from "../styles/TextStyles.styled"
import GatsbyImageWithIEPolyfill from "gatsby-image/withIEPolyfill"

const Avatar = ({ name, image, isAvatarRound, ...props }) => (
  <S.Container
    {...props}
    className="avatar"
    round={isAvatarRound}
    imgWidth={image?.normal?.width}
  >
    {image &&
      (image.round || image.normal ? (
        <GatsbyImageWithIEPolyfill
          fixed={isAvatarRound && image.round}
          fluid={!isAvatarRound && image.normal}
          url={image.url}
        />
      ) : (
        <img src={image.url} />
      ))}
    <Span style={{ whiteSpace: "pre-wrap" }}>{name}</Span>
  </S.Container>
)

export default Avatar
