import React, { useContext } from "react"
import Img from "gatsby-image"
import { H6, P, SubTitle } from "../../components/styles/TextStyles.styled"
import dark from "../../theme/dark"
import ArrowedLink from "../../components/Links/ArrowedLink"
import * as S from "./LinkedHero.styled"
import { urlFor } from "../../util/content-links"
import TagSet from "../../components/TagSet"
import { graphql, useStaticQuery } from "gatsby"
import SearchContext from "../../context/SearchContext"
import GridLayout from "../../layouts/GridLayout"
import { DomainAwareThemeProvider } from "../../components/DomainAwareThemeProvider"

export default ({ visual, title, item, ...props }) => {
  const { allDatoCmsTranslation } = useStaticQuery(graphql`
    {
      allDatoCmsTranslation {
        edges {
          node {
            tags
            learnMore
            locale
          }
        }
      }
    }
  `)
  const labels = allDatoCmsTranslation.edges.find(
    edge => edge.node.locale === item.locale
  ).node

  const { search } = useContext(SearchContext)
  return (
    <S.Container {...props}>
      <Img
        fluid={visual.fluid}
        style={{ maxHeight: "800px", minHeight: "610px" }}
      />
      <DomainAwareThemeProvider theme={dark}>
        <S.ContentContainer accountPadding={props.padTop}>
          <GridLayout>
            {title && <H6>{title}</H6>}
            <SubTitle className="article-title">{item.title}</SubTitle>
            <P className="description">{item.seo.description}</P>
            <ArrowedLink
              hideBackground
              link={{
                label: labels.learnMore,
                url: urlFor(item.__typename, item.locale, item.slug),
              }}
            />
            {(item.categories || item.tags) && (
              <TagSet
                className="tags"
                using={item.categories || item.tags}
                title={labels.tags}
                abbreviate
                onTagClick={t => search(item.locale, [t.originalId], null)}
              />
            )}
          </GridLayout>
        </S.ContentContainer>
      </DomainAwareThemeProvider>
    </S.Container>
  )
}
